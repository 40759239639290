/**
 *
 * Notification
 * @author Chad Watson
 *
 */

import { RadialAlertIcon } from "components/Icons";
import ListItem from "components/ListItem";
import NakedButton from "components/NakedButton";
import ProgressBar from "components/ProgressBar";
import Space from "components/Space";
import { BORDER_RADIUS } from "constants/index";
import { NOTIFICATION_TYPES } from "constants/notifications";
import {
  defaultThemeBoxShadow,
  themeBlueDark,
  themeFailure,
  themeGrayLight,
  themeGrayXlight,
  themePanelBorder,
  themeWarning,
} from "containers/Theme";
import { useIntl } from "hooks/useIntl";
import PropTypes from "prop-types";
import { compose, is } from "ramda";
import React from "react";
import { withRouter } from "react-router";
import { withHandlers } from "recompose";
import styled from "styled-components/macro";
import messages from "./messages";

import DmpIcon from "common/components/DmpIcon";
import noop from "common/utils/universal/noop";

export const NOTIFICATION_HEIGHT = 70;
export const TALL_NOTIFICATION_HEIGHT = 120;

const BaseContainer = styled.li`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${NOTIFICATION_HEIGHT}px;
  width: 300px;
  border-radius: ${BORDER_RADIUS};
  box-shadow: ${defaultThemeBoxShadow};
  display: block;
`;
const DefaultContainer = styled(BaseContainer)`
  border: ${themePanelBorder};
  background: white;
`;
const WarningContainer = styled(BaseContainer)`
  background: ${themeWarning};
  color: white;
`;
const ErrorContainer = styled(BaseContainer)`
  background: ${themeFailure};
  color: white;
`;
const ActionableContainer = styled(DefaultContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: ${TALL_NOTIFICATION_HEIGHT}px;
`;
const ProgressContainer = styled(ActionableContainer)`
  height: ${TALL_NOTIFICATION_HEIGHT}px;
`;
const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px 14px;
  text-align: left;
`;
const IconContainer = styled.div`
  display: block;
  margin-right: 10px;
  font-size: 2em;
  line-height: 0;
`;
const Message = styled.p`
  margin: 0;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.5;
`;
const CloseButton = styled(NakedButton)`
  display: flex;
  place-self: flex-start;
  justify-content: end;
  margin: 0.25rem;
  padding-left: 1rem;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${themeGrayLight};
  width: 100%;
  padding: 0;
`;
const Button = styled(NakedButton)`
  width: 100%;
  padding: 0.5em;
  font-size: 14px;
  font-weight: 500;
  height: 3em;

  &:not(:last-child) {
    border-right: 1px solid ${themeGrayLight};
  }

  &:active {
    color: white;
    background-color: ${themeBlueDark};
  }

  &:hover {
    background-color: ${themeGrayXlight};
  }
`;
const RightArrow = styled(ListItem.Right)`
  font-size: 0.75em !important;
`;
const ProgressBarContainer = styled.div`
  padding: 10px 14px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const SingleMessageHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
const SpacedMessageContainer = styled(MessageContainer)`
  justify-content: space-between;
  width: 100%;
`;

const CONTAINERS_BY_TYPE = {
  [NOTIFICATION_TYPES.DEFAULT]: DefaultContainer,
  [NOTIFICATION_TYPES.WARNING]: WarningContainer,
  [NOTIFICATION_TYPES.ERROR]: ErrorContainer,
  [NOTIFICATION_TYPES.ACTIONABLE]: ActionableContainer,
  [NOTIFICATION_TYPES.PROGRESS]: ProgressContainer,
};

const Notification = ({
  style,
  type,
  message,
  messageValues,
  onClose,
  onMouseEnter,
  onMouseLeave,
  includeCloseButton,
  notification,
}) => {
  const { WARNING, ERROR } = NOTIFICATION_TYPES;
  const Container = CONTAINERS_BY_TYPE[type];
  const { formatMessage } = useIntl();

  return (
    <Container
      type={type}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <MessageContainer>
        {[WARNING, ERROR].includes(type) && (
          <IconContainer>
            <RadialAlertIcon />
          </IconContainer>
        )}
        <Message>
          {is(String, message)
            ? message
            : formatMessage(message, messageValues)}
        </Message>
        {includeCloseButton ? (
          <CloseButton onClick={onClose}>
            <DmpIcon icon="close_cancel" constrainToParent={false} size={10} />
          </CloseButton>
        ) : null}
      </MessageContainer>
    </Container>
  );
};

Notification.propTypes = {
  type: PropTypes.oneOf(Object.values(NOTIFICATION_TYPES)),
  onClose: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.object,
  messageValues: PropTypes.object,
  includeCloseButton: PropTypes.bool,
  notification: PropTypes.any,
};

Notification.defaultProps = {
  type: NOTIFICATION_TYPES.DEFAULT,
  style: {},
};

const withDefaultHandlers = withHandlers({
  onClose:
    ({ notification, onClose }) =>
    () =>
      onClose(notification.get("id")),
  onMouseEnter:
    ({ notification, onMouseEnter }) =>
    () =>
      onMouseEnter(notification.get("id")),
  onMouseLeave:
    ({ notification, onMouseLeave }) =>
    () =>
      onMouseLeave(notification.get("id")),
});

export default withDefaultHandlers(Notification);

const BareActionableNotification = ({
  style,
  message,
  messageValues,
  payload,
  onClose,
  onMouseEnter,
  onMouseLeave,
}) => {
  const Container = CONTAINERS_BY_TYPE[NOTIFICATION_TYPES.ACTIONABLE];
  const { formatMessage } = useIntl();

  return (
    <Container
      type={NOTIFICATION_TYPES.ACTIONABLE}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <MessageContainer
        onClick={() => {
          onClose();
          payload.onMessageClick && payload.onMessageClick();
        }}
        style={{
          justifyContent: payload.onMessageClick ? "space-between" : "center",
          width: "100%",
        }}
        as={NakedButton}
      >
        <Message as="span">
          {is(String, message)
            ? message
            : formatMessage(message, messageValues)}
        </Message>
        {payload.onMessageClick && <RightArrow />}
      </MessageContainer>
      <ButtonContainer>
        {payload.buttons.map(({ label, onClick }) => (
          <Button
            onClick={() => {
              onClose();
              onClick();
            }}
            key={label}
          >
            {label}
          </Button>
        ))}
      </ButtonContainer>
    </Container>
  );
};

BareActionableNotification.propTypes = {
  type: PropTypes.oneOf(Object.values(NOTIFICATION_TYPES)),
  onClose: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  payload: PropTypes.object,
  style: PropTypes.object,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  messageValues: PropTypes.object,
};

BareActionableNotification.defaultProps = {
  type: NOTIFICATION_TYPES.DEFAULT,
  style: {},
};

export const ActionableNotification = withDefaultHandlers(
  BareActionableNotification
);

const BareProgressNotification = ({
  style,
  payload = {},
  onClose,
  onMouseEnter,
  onMouseLeave,
  message,
  messageValues,
}) => {
  const {
    onIgnore = noop,
    onClick = noop,
    isVisible = true,
    percentComplete = 0,
    showProgress = true,
  } = payload;
  const Container = CONTAINERS_BY_TYPE[NOTIFICATION_TYPES.PROGRESS];
  const { formatMessage } = useIntl();

  if (!isVisible) {
    return null;
  }

  return showProgress ? (
    <Container
      type={NOTIFICATION_TYPES.PROGRESS}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <SpacedMessageContainer onClick={onClick} as={NakedButton}>
        <SingleMessageHolder>
          <Message as="span">
            {is(String, message)
              ? message
              : formatMessage(message, messageValues)}
          </Message>
        </SingleMessageHolder>
        <RightArrow />
      </SpacedMessageContainer>
      <ProgressBarContainer>
        <ProgressBar percentage={percentComplete} />
        <Space horizontal small />
        {`${percentComplete}%`}
      </ProgressBarContainer>
    </Container>
  ) : (
    <BareActionableNotification
      style={style}
      message={message}
      messageValues={messageValues}
      payload={{
        onMessageClick: onClick,
        buttons: [
          {
            label: formatMessage(messages.viewResults),
            onClick: onClick,
          },
          {
            label: formatMessage(messages.ok),
            onClick: () => {
              onIgnore();
              onClose();
            },
          },
        ],
      }}
      onClose={onClose}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};

BareProgressNotification.propTypes = {
  type: PropTypes.oneOf(Object.values(NOTIFICATION_TYPES)),
  onClose: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  payload: PropTypes.object,
  style: PropTypes.object,
  messageValues: PropTypes.object,
};

BareProgressNotification.defaultProps = {
  type: NOTIFICATION_TYPES.DEFAULT,
  style: {},
};

export const ProgressNotification = compose(
  withDefaultHandlers,
  withRouter
)(BareProgressNotification);

/**
 *
 * RadialButtonIcon
 * @author Chad Watson
 *
 */
import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const RadialButtonIcon = props => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <g>
        <path
          d="M54.633,9.47c-6.049-6.035-14.087-9.358-22.646-9.358c-8.547,0-16.582,3.321-22.626,9.353
      C3.325,15.487,0.001,23.509,0,32.052C0,40.6,3.326,48.625,9.366,54.654C15.405,60.68,23.446,64,32.009,64h0.003
      c8.557,0,16.593-3.316,22.628-9.338C60.676,48.637,64,40.615,64,32.071C64,23.524,60.674,15.498,54.633,9.47z M51.813,51.83
      C46.534,57.1,39.502,60,32.012,60H32.01c-7.496,0-14.534-2.904-19.817-8.178C6.909,46.551,4,39.529,4,32.053
      c0.001-7.474,2.908-14.49,8.187-19.757c5.288-5.278,12.32-8.185,19.815-8.185c7.478,0,14.512,2.909,19.806,8.19
      C57.091,17.573,60,24.595,60,32.071C60,39.545,57.093,46.562,51.813,51.83z"
        />
        <path d="M32,20c-6.617,0-12,5.383-12,12s5.383,12,12,12s12-5.383,12-12S38.617,20,32,20z" />
      </g>
      <rect fill="none" width="64" height="64" />
    </g>
  </SvgIcon>
);

export default RadialButtonIcon;

/**
 *
 * Notifications
 * @author Chad Watson
 *
 */

import NakedList from "components/NakedList";
import { NOTIFICATION_TYPES } from "constants/notifications";
import PropTypes from "prop-types";
import { __, always, applySpec, compose, has, identity } from "ramda";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { TransitionMotion, spring } from "react-motion";
import styled from "styled-components/macro";
import { immutableGet } from "utils";
import { seconds } from "utils/dates";
import Notification, {
  ActionableNotification,
  NOTIFICATION_HEIGHT,
  ProgressNotification,
  TALL_NOTIFICATION_HEIGHT,
} from "./Notification";

const NOTIFICATION_MARGIN = 10;
const INITIAL_EXIT_DELAY = seconds(5);
// const INTERRUPTED_EXIT_DELAY = seconds(2.5);

const List = styled(NakedList)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
`;

const getTranslateY = (notifications, index) =>
  notifications.reduceRight(
    (acc, notification, currentIndex) =>
      currentIndex > notifications.size - index - 1
        ? notification.get("type") === NOTIFICATION_TYPES.ACTIONABLE ||
          notification.get("type") === NOTIFICATION_TYPES.PROGRESS
          ? acc - TALL_NOTIFICATION_HEIGHT - NOTIFICATION_MARGIN
          : acc - NOTIFICATION_HEIGHT - NOTIFICATION_MARGIN
        : acc,
    0
  );

const createTransitionStyles = (notifications) =>
  notifications
    .valueSeq()
    .reverse()
    .map(
      applySpec({
        key: immutableGet("id"),
        style: {
          translateY: compose(spring, (_, index) =>
            getTranslateY(notifications, index)
          ),
          opacity: always(spring(1)),
          scale: always(spring(1)),
        },
        data: identity,
      })
    )
    .toArray();

const willLeave = ({ style }) => ({
  translateY: style.translateY,
  scale: spring(0.8),
  opacity: spring(0),
});

const willEnter = () => ({
  translateY: 0,
  scale: 0.8,
  opacity: 0,
});

const createInlineStyle = ({ opacity, scale, translateY }) => ({
  transform: `scale(${scale}) translateY(${translateY}px)`,
  opacity,
});

class Notifications extends React.Component {
  static propTypes = {
    notifications: ImmutablePropTypes.listOf(
      ImmutablePropTypes.contains({
        type: PropTypes.oneOf(Object.values(NOTIFICATION_TYPES)),
        id: PropTypes.string,
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
          .isRequired,
        autoDismiss: PropTypes.bool,
        includeCloseButton: PropTypes.bool,
      })
    ).isRequired,
    onClearNotification: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.queueExitsForNewNotifications();
  }

  componentDidUpdate() {
    this.queueExitsForNewNotifications();
  }

  exitQueue = {};

  queueExitsForNewNotifications = () => {
    this.props.notifications
      .valueSeq()
      .filter(immutableGet("autoDismiss"))
      .map((notification) => notification.get("id"))
      .filterNot(has(__, this.exitQueue))
      .forEach((id) => {
        this.exitQueue[id] = setTimeout(
          this.clearNotification,
          INITIAL_EXIT_DELAY,
          id
        );
      });
  };

  clearNotification = (id) => {
    clearTimeout(this.exitQueue[id]);
    delete this.exitQueue[id];
    this.props.onClearNotification(id);
  };

  handleNotificationMouseEnter = (id) => {
    clearTimeout(this.exitQueue[id]);
  };

  handleNotificationMouseLeave = (id) => {
    this.props.notifications.forEach((notification) => {
      if (notification.get("id") === id && notification.get("autoDismiss")) {
        this.exitQueue[id] = setTimeout(
          this.clearNotification,
          INITIAL_EXIT_DELAY,
          id
        );
      }
    });
  };

  render() {
    return (
      <TransitionMotion
        styles={createTransitionStyles(this.props.notifications)}
        willEnter={willEnter}
        willLeave={willLeave}
      >
        {(interpolatedStyles) => (
          <List>
            {interpolatedStyles.map(({ key, style, data }) =>
              data.get("type") === NOTIFICATION_TYPES.ACTIONABLE ? (
                <ActionableNotification
                  id={data.get("id")}
                  key={key}
                  message={data.get("message")}
                  messageValues={data.get("messageValues")}
                  payload={data.get("payload")}
                  notification={data}
                  includeCloseButton={data.get("includeCloseButton")}
                  onClose={this.clearNotification}
                  onMouseEnter={this.handleNotificationMouseEnter}
                  onMouseLeave={this.handleNotificationMouseLeave}
                  style={createInlineStyle(style)}
                />
              ) : data.get("type") === NOTIFICATION_TYPES.PROGRESS ? (
                <ProgressNotification
                  id={data.get("id")}
                  key={key}
                  message={data.get("message")}
                  messageValues={data.get("messageValues")}
                  payload={data.get("payload")}
                  notification={data}
                  includeCloseButton={data.get("includeCloseButton")}
                  onClose={this.clearNotification}
                  onMouseEnter={this.handleNotificationMouseEnter}
                  onMouseLeave={this.handleNotificationMouseLeave}
                  style={createInlineStyle(style)}
                />
              ) : (
                <Notification
                  id={data.get("id")}
                  key={key}
                  message={data.get("message")}
                  messageValues={data.get("messageValues")}
                  notification={data}
                  includeCloseButton={data.get("includeCloseButton")}
                  onClose={this.clearNotification}
                  onMouseEnter={this.handleNotificationMouseEnter}
                  onMouseLeave={this.handleNotificationMouseLeave}
                  style={createInlineStyle(style)}
                  type={data.get("type")}
                />
              )
            )}
          </List>
        )}
      </TransitionMotion>
    );
  }
}

export default Notifications;
